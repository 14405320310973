import React from "react"
import BgImg from "../images/404.jpg"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const NotFoundPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Oops."
    SecondText="It looks like that page doesn't exist."
  >
    <SEO title="404: Not found" />
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      We couldn't Find that page.
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      We are sorry for the incovenience. Why don't you press the back button or
      find some more information above.
    </ParagraphText>
  </Layout>
)

export default NotFoundPage
